.footer-container{
    position: relative;
}

.footer-container>hr{
    border: 3px solid var(--lightgray);
}


.footer{
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    gap: 4rem;
    align-items: center;
    justify-content: center;
    height: 20rem;
}


.social-links{
    display: flex;
    gap: 4rem;

}

.social-links>img{
    width: 2rem;
    height: 2rem;
    cursor: pointer;
}



.logo-f>img{
    width: 10rem;
    

}


.blur-f-1{
    width: 22rem;
    height: 12rem;
    right: 35%;
    

}


